import {alertService} from "@/services/alert/alert.service";
import {guestSettingCarService} from "@/services/guest/setting/car.service";
import {guestReservationsService} from "@/services/guest/reservations/reservations.service";
import InventoryCalendar from '../inventory/inventory.component.vue'
import moment from "moment";
import {flightService} from "../../../../services/guest/flight/flight.service";
import {guestSettingCardService} from "@/services/guest/setting/card.service"; // ユーザー情報（カード登録が実施済か確認のため）

export default {
    components: {
        InventoryCalendar,
    },
    data() {
        return {
            timeAry: [], // selectboxで使用する1時間単位の値 { text: "00:00", value: "00:00:00" }
            valid: false, // 予約入力フォームのバリデーションチェック（全体）
            timeValid: false, // 予約入力フォームのバリデーションチェック(時間) 
            cancelDialog: false,
            user: {}, // ユーザー情報（カード登録が実施済か確認のため）
            userReady: false, // ユーザー情報取得済（カード登録が実施済か確認のため）
            ready: false, // 予約準備
            reservable: true,
            form: 'select_date',
            formError: false,
            entryDate: '',
            exitDate: '',
            entryTime: null,
            exitTime: null,
            carInformation: null,
            coupon_code: null,
            driverFlying: 0,
            parkingId: '',
            parkingType: '',
            parkingName: '',
            cars: {},
            entry_date: '',
            exit_date: '',
            flight: {},
            fee: {},
            paymentSetting: {},
            flight_schedules: {
                departure: [],
                arrival: []
            },
            flight_airlines: {
                departure: [],
                arrival: []
            },
            flight_destination: {
                departure: [],
                arrival: []
            },
            errors: {
                coupon_code: false
            },
            post_parking: true
        }
    },

    filters: {
        // 3桁ごとにコンマを入れるフィルター
        numberFormat: function (value) {
            if (!value || value == 0) return 0;
            value = value.toString();
            return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        // 00:00:00を00:00に変更するフィルター
        timeShorten: function(value) {
            if (!value) return '';
            return value.slice(0, 5);
        },
        // 2000-01-01 → 1月1日（土）変換
        formatDate: function(dateString) {
            const date = new Date(dateString);
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
            const dayOfWeek = daysOfWeek[date.getDay()];
            return `${month}月${day}日（${dayOfWeek}）`;
        },
    },
    created() {
        // timeAryにデータを追加
        this.generateTimeArray();
    },
    async mounted() {
        this.ready = false; // 予約準備
        await this.getUser(); // ユーザー情報取得（カード登録が実施済か確認のため）
        this.list$();
        if (this.$route.params.id) {
            this.form = "input_form";
            await this.getReservation();
            await this.getFee(this.getFeeParameter());
        }
        await this.getPaymentSetting({
            paginate: 0,
            per_page: 0,
            keyword: "payment_setting"
        });
    },
    computed: {
        formData() {
            return {
                parking_id: this.parkingId,
                car_id: this.carInformation.id,
                flight_use: this.driverFlying,
                parking_type: this.parkingType,
                coupon_code: this.coupon_code,
                entry_date: this.entryDate,
                entry_time: this.entryTime,
                exit_date: this.exitDate,
                exit_time: this.exitTime,
                region: this.carInformation.region,
                registered_number: this.carInformation.registered_number,
                hiragana: this.carInformation.hiragana,
                sort_number: this.carInformation.sort_number
            }
        }
    },
    watch: {
        entryTime: 'checkConditions',
        exitTime: 'checkConditions',
        carInformation: 'checkConditions',
        coupon_code: 'checkConditions',
    },
    methods: {
        async reservableCheck (){
            await alertService.loading();
            const params = {
                airport_id : 1,
                parking_id: this.parkingId,
                entry_date: this.entryDate,
                entry_time: this.entryTime,
                exit_date: this.exitDate,
                exit_time: this.exitTime,
            }
            await guestReservationsService.reservable(params)
            .then((response) => {
                this.reservable = response.data.status;
                if (this.reservable) {
                    this.form = 'input_form';
                }
            }).catch((error) => {
                alert("空き状況を確認することができませんでした");
            }).finally(() => {
                alertService.close();
            });
        },
        checkAvailability(){
            if (!this.$refs.time_form.validate()) {
                this.formError = true;
                return
            }
            this.formError = false;
            this.reservableCheck()
        },
        // 料金算出パラメーター
        getFeeParameter(){
            let filter = {
                parking_id: this.parkingId,
                entry_date: this.entryDate,
                entry_time: this.entryTime,
                exit_date: this.exitDate,
                exit_time: this.exitTime,
                coupon_code: this.coupon_code,
                region: this.carInformation.region,
                registered_number: this.carInformation.registered_number,
                hiragana: this.carInformation.hiragana,
                sort_number: this.carInformation.sort_number
            };
            return filter
        },
        // ユーザー情報取得確認（カード登録が実施済か確認のため）
        async getUser(id) {
            await alertService.loading();
            this.userReady = false;
            var response = await guestSettingCardService.get();
            this.user = response.data;
            this.userReady = true;
            this.ready = true;
            alertService.close();
        },
        // timeAryの値入力処理
        generateTimeArray() {
            for (let i = 0; i < 24; i++) {
                const hour = i.toString().padStart(2, '0');
                this.timeAry.push({
                    text: `${hour}:00`,
                    value: `${hour}:00:00`
                });
            }
        },
        handleDateSelect(selectInfo) {
            switch (true) {
                case !this.entryDate:
                    this.entryDate = selectInfo.startStr
                    break;

                case !this.exitDate:
                    this.exitDate = selectInfo.startStr
                    break;

                default:
                    this.entryDate = selectInfo.startStr
                    this.exitDate = ''
                    break;
            }
        },
        back() {
            if (this.$route.params.id) {
                this.$router.push('/guest/dashboard');
                return
            }
            this.$router.go();
        },
        setData(data) {
            this.parkingId = data.parking_id;
            this.parkingName = data.parking_name;
            this.entryDate = data.entry_date;
            this.exitDate = data.exit_date;
            this.parkingType = data.parking_type;
            this.$nextTick(() => {
                this.form = 'input_time'
            });
        },
        formatTime(time) {
            var times = new Date(`${this.entryDate} ${time}`);
            return times.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
        },
        // 料金計算に必須な項目の確認
        checkConditions() {
            if (this.entryTime != null && this.exitTime != null && this.carInformation != null) {
                this.feeCalculation$();
            }
        },
        // 料金算出
        async feeCalculation$(){
            if (this.form == 'input_form') {
                await this.getFee(this.getFeeParameter());
            }
        },
        // 予約入力画面まで戻る & 与えられた数字までスクロール
        scrollToTarget(num) {
            this.form = 'input_form';
            this.$nextTick(() => {
                window.scrollTo({ top: num, behavior: 'smooth' });
            });
        },
        // 予約開始日ルール
        validateEntryTime() {
            if (!this.entryTime) return '';
            if (this.isFutureDate()) return '終了日と同時刻、または未来に設定できません';
            return true;
        },
        // 予約終了日ルール
        validateExitTime() {
            if (!this.exitTime) return '';
            if (this.isFutureDate()) return '開始日と同時刻、または過去に設定できません';
            return true;
        },
        // 予約開始日 > 予約終了日 ルール
        isFutureDate() {
            if (!this.entryDate || !this.entryTime || !this.exitDate || !this.exitTime) {
                return false;
            }
            const entryDateTime = new Date(`${this.entryDate}T${this.entryTime}`);
            const exitDateTime = new Date(`${this.exitDate}T${this.exitTime}`);
            return entryDateTime >= exitDateTime;
        },
        // フライトありを選択してる場合の入力必須ルール
        requiredIfFlying(value) {
            if (this.driverFlying === 1 && !value) {
                return '';
            }
                return true;
        },
        async store$() {
            if (!this.$refs.form.validate()) {
                this.formError = true;
                return
            }
            if (this.form == true) {
                var callback = async () => {
                    await alertService.loading();
                    await guestReservationsService.store({...this.formData, ...this.flight}).then((response) => {

                        console.log('response', response);

                        alertService.close();
                        this.$router.push('success');
                    }).catch((error) => {
                        this.form = 'input_form';
                        this.formError = true;

                        this.errors = error.errors || error.error

                        if(this.errors !== 'Please register your credit card.') {
                            alertService.close();
                            alertService.error(this.$t('guest.global.error'), this.$t('guest.reservation.reached-max-of-reservation'));
                        } else {
                            alertService.close();
                            alertService.error(this.$t('guest.global.error'), this.$t('guest.reservation.register-credit-card'));
                        }
                    });
                }
                alertService.confirmation(callback, this.$t("guest.reservation.confirmation"), this.$t("guest.reservation.are-you-sure"));
            }
            if (this.form == 'input_form') {
                this.formError = false
                this.errors.coupon_code = false
                let validFlightReservation = true;
                if(this.driverFlying){
                    if(Object.keys(this.flight).length != 8){
                        validFlightReservation = false;
                    }
                }
                if (this.entryTime != null && this.exitTime != null && this.carInformation != null && validFlightReservation) {
                    await this.getFee(this.getFeeParameter());
                    if(!this.errors.coupon_code){
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        this.form = true;
                    }
                } else {
                    this.formError = true
                }
            }
        },
        async list$() {
            await guestSettingCarService.list({
                paginate: 0,
                per_page: 10,
            }).then((response) => {
                this.cars = response.data
            }).catch((error) => {
            });
        },

        async getReservation() {

            await guestReservationsService.show(this.$route.params.id).then(async (response) => {
                let parking = response.data;

                this.entry_date = parking.entry_date;
                this.coupon_code = parking.coupon_code;
                this.entry_date = parking.entry_date;
                this.exit_date = parking.exit_date;
                this.entryTime = this.formatTimeApi(parking.entry_time);
                this.exitTime = this.formatTimeApi(parking.exit_time);
                delete parking.car.booking_user
                this.carInformation = parking.car;
                this.driverFlying = parking.flight_use;
                this.parkingId = parking.parking_id;
                this.parkingName = parking.parking.name;
                this.parkingType = parking.parking.parking_type;

                this.flight = {};

                if(parking.flight_details){

                    this.flight = parking.flight_details;

                    await this.reload_flight();
                }


            }).catch((error) => {
            }).finally(() => {
                this.entryDate = this.entry_date
                this.exitDate = this.exit_date
            });
        },
        async getFee(data) {
           await guestReservationsService.fee(data).then(async(result) => {
                this.fee = result.data;
                this.errors.coupon_code = false;
           }).catch(error => {

                if(error.message == "Invalid Coupon."){
                    this.errors.coupon_code = true;
                }
           })

        },

        async getPaymentSetting(data) {
            await guestReservationsService.getPaymentSetting(data).then(async(result) => {
                 this.paymentSetting = result.data[0];
                 if(this.paymentSetting.value.parking_charge != "prepay") {
                    this.parking_fee = 0;
                    this.post_parking = false;
                 }
            }).catch(error => {
                this.error = error.message;
            })
         },


        async update$() {
            if (this.form == 'input_form') {
                this.formError = false

                let validFlightReservation = true;
                if(this.driverFlying){
                    if(Object.keys(this.flight).length <= 7){
                        validFlightReservation = false;
                    }
                }

                if (this.entryTime != null && this.exitTime != null && this.carInformation != null && validFlightReservation) {

                    await this.getFee(this.getFeeParameter());
                    if(!this.errors.coupon_code){
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        this.form = true
                    }
                } else {
                    this.formError = true
                }
                return;
            }

            var callback = async () => {
                await alertService.loading();
                await guestReservationsService.update(this.$route.params.id, this.formData).then((response) => {
                    alertService.success(this.$t('guest.global.success'), this.$t('guest.reservation.reservation-updated-success'));
                }).catch((error) => {
                    this.error = error
                    alertService.close();
                }).finally(() => {
                    this.$router.push('/guest/reservations');
                });
            }
            alertService.confirmation(callback, this.$t("guest.reservation.confirmation"), this.$t("guest.reservation.are-you-sure"));
        },

        formatTimeApi(time) {
            return moment(time, "HH:mm A").format("HH:mm:ss");
        },

        formatTimePayload(time) {
            return moment(time, "HH:mm:ss").format("HH:mm A");
        },

        checkEntryTime() {

            if (this.entryDate == this.exitDate) {
                if (!moment(this.exitTime, "HH:mm:ss").isAfter(moment(this.entryTime, "HH:mm:ss"))) {

                    this.exitTime = null;
                }
            }

        },
        async reload_flight(){

            if(this.flight.dep_flight_date){
                await  this.selectFlightDate('departure',this.flight.dep_flight_date);
            }
            if(this.flight.dep_airline_code){
                await   this.selectAirline('departure',this.flight.dep_airline_code);
            }
            if(this.flight.destination){
                await  this.selectDestination('departure',this.flight.destination,'departure_port');
            }
            if(this.flight.dep_flight_date){
                await    this.selectFlightDate('arrival',this.flight.arr_flight_date);
            }
            if(this.flight.dep_airline_code){
                await     this.selectAirline('arrival',this.flight.arr_airline_code);
            }
            if(this.flight.destination){
                await   this.selectDestination('arrival',this.flight.origin,'arrival_port');
            }
        },
        async selectFlightDate(mode,$event){
            let query = {
                departure_date: $event,
                paginate: 0
            }
            let result = await flightService.list(query);
            this.flight_schedules[mode] = result.data;
        },
        async selectAirline(mode,$event){

            this.flight_airlines[mode] = this.flight_schedules[mode].filter(el => el.airline_code == $event);
        },
        async selectDestination(mode,$event,key){
            this.flight_destination[mode] = this.flight_airlines[mode].filter(el => el[key] == $event);
        }
    }
}

import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FlightService {

    async list(query) {
        promise =  api.client.get(config.end_point.guest.flights, {params: query});
        return promise;
    }
    // async list(query) {
    //     console.log(query)
    //     promise =  api.client.get(config.end_point.guest.flights, {params: {departure_date: '06/20/2024', paginate: 0}});
    //     return promise;
    // }
}

export const flightService = new FlightService();
